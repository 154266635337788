@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

.content-container {
  max-width: 1536px; /* or any width you prefer */
  margin: 0 auto;
  padding: 0;
}

.navbar {
  transition: background-color 0.3s;
}

.navbar.active {
  background-color: rgba(0, 0, 0, 0.8);
}

.activeMenu {
  color: #f7426f !important;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}

.marquee p {
  display: inline-block;
  padding-right: 70px; /* Adjust the space between the items if necessary */
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
